<template>
  <div
    style="
      margin: 30px 0;
      display: grid;
      align-items: center;
      justify-content: center;
      gap: 30px;
      grid-template-columns: auto auto auto;
    "
  >
    <div
      class="card"
      style="margin: 20px 0"
      v-for="one_news in news"
      :key="one_news.id"
      @click="detail_news(one_news.id)"
    >
      <div class="text" style="display: grid; gap: 10px">
        <div class="title" style="color: #ff4b00">
          {{ one_news.last_modified }}
        </div>
        <img :src="one_news.img" />
        <div class="subtitle">
          {{ one_news.title }}
        </div>
        <p
          style="color: #ff4b00; cursor: pointer"
          @click="detail_news(one_news.id)"
        >
          Подробнее
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "history",
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    this.getNews();
    // console.log(this.$route.params);
  },
  methods: {
    async getNews() {
      const res = await fetch("https://cb96744.tmweb.ru/api/news/");
      const finalRes = await res.json();
      this.news = finalRes;
      console.log(finalRes);
    },
    detail_news(id) {
      this.$router.push("/news/" + id);
    },
  },
};
</script>
<style scoped>
div {
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
.card {
  width: 402px;
  height: 402px;
  float: left;
  margin: 0;
  text-align: center;
  background: #ffffff;
  /* box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
}
.card img {
  width: 100%;
  height: 280px;
  border-radius: 5px;
  object-fit: cover;
}
</style>
